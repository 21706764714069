@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;350;400;450;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{text-decoration:none}a:visited{color:inherit}a{color:black !important}a:visited{color:black !important}li,p{line-height:1.5}h2{margin:8px 0;line-height:1.4em}h1{font-size:2.8em;font-weight:400}h4{text-transform:uppercase;margin:5px 0}body{margin:0 !important;font-family:'Roboto', sans-serif;color:#34373d;font-weight:300}::-webkit-scrollbar{width:5px;background-color:#f9f9fd}::-webkit-scrollbar-thumb{border-radius:10px;background-color:#9299a7}::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.2);background-color:#f9f9fd}.main_loader__2CBxF{width:100%;height:100%;position:absolute;display:flex;justify-content:center;text-align:center;align-items:center}.main_background__3Dmox{background-image:url(/static/media/vosxod-kosmos-planeta-oblaka.052474a4.jpg);background-repeat:no-repeat;background-size:cover;background-attachment:fixed;position:fixed;content:'';display:block;width:100%;height:100%;z-index:0}.main_wrapper__28lmz{z-index:1;height:100%;width:100%;display:flex;flex-direction:column;flex-grow:1;background:rgba(8,37,99,0.49);padding-left:78px;box-sizing:border-box}.main_content__15TtJ{flex-grow:1;display:flex;width:100%;height:100%;z-index:3;box-sizing:border-box;min-height:100vh;background:#fff;padding:0 20px 10px;flex-direction:column}@media only screen and (max-width: 930px){.main_content__15TtJ{width:100vw}.main_content__15TtJ>*{padding:0;border-radius:0}.main_wrapper__28lmz{padding:60px 0 0}}

.Sidebar_menuList__aYn3e{position:fixed !important;left:0;top:0;height:100%;padding:6px 10px;width:78px}.Sidebar_menuList__aYn3e .Sidebar_logo__3Cych{margin:20px auto;width:40px;height:40px}.Sidebar_menuList__aYn3e .Sidebar_logo__3Cych img{width:100%}.Sidebar_menuList__aYn3e a>*{padding:8px 4px !important;color:white !important;font-size:13px !important}.Sidebar_divider__1yCxH{background:white}

.Loader_loader__3YZzz{width:100%;position:fixed;justify-content:center;align-items:center;height:100%;background:rgba(255,255,255,0.5);z-index:10000;display:flex;left:0;top:0}

a{text-decoration:none}a:visited{color:inherit}

