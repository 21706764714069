@import '../shared/style';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;350;400;450;500&display=swap');

a {
  color: black !important;
}

a:visited {
  color: black !important;
}

li, p {
  line-height: 1.5;
}

h2 {
  margin: 8px 0;
  line-height: 1.4em;
}

h1 {
  font-size: 2.8em;
  font-weight: 400;
}

h4 {
  text-transform: uppercase;
  margin: 5px 0;
}

body {
  margin: 0 !important;
  font-family: 'Roboto', sans-serif;
  color: #34373d;
  font-weight: 300;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f9f9fd;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $gray-100;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #f9f9fd;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.background {
  background-image: url('../shared/vosxod-kosmos-planeta-oblaka.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.wrapper {
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: rgba(8, 37, 99, 0.49);
  padding-left: 78px;
  box-sizing: border-box;
}

.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 3;
  box-sizing: border-box;
  min-height: 100vh;
  background: rgba(255, 255, 255, 1);
  padding: 0 20px 10px;
  flex-direction: column;
}

@media only screen and (max-width: 930px) {
  .content {
    width: 100vw;

    & > * {
      padding: 0;
      border-radius: 0;
    }
  }

  .wrapper {
    padding: 60px 0 0;
  }
}
