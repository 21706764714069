.loader{
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: rgba(255,255,255,.5);
  z-index: 10000;
  display: flex;
  left: 0;
  top: 0;
}
