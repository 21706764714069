.menuList {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100%;
  padding: 6px 10px;
  width: 78px;

  .logo {
    margin: 20px auto;
    width: 40px;
    height: 40px;

    img {
      width: 100%;
    }
  }

  a > {
    * {
      padding: 8px 4px !important;
      color: white !important;
      font-size: 13px !important;
    }
  }
}

.divider {
  background: white;
}
